import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Temoignage from "../components/Home/Temoignage"
import Contact from "../components/Home/Contact"
import ReferencesList from "../components/References"
import Filter from "../components/References/Filter"
import BannerV2 from "../components/elements/BannersV2/BannerV2"
const ResultText = styled.p`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const bannerLeftCardContent = {
  mainContent: [
    `De la startup aux grands comptes, SLAP digital a accompagné plus de 300 clients depuis 2011
    dans la définition et l'optimisation de leur stratégie digitale. Venez nous challenger, nous en
    ferons autant !`,
  ],
  titleFirstLine: "+ de 60 clients",
  titleSecondLine: "nous font confiance",
  subTitle: "Références & Cas clients",
}

const References = ({ data, location }) => {
  const [sector, setSector] = useState("Tous")

  const handleClickSector = (value, callback) => {
    setSector(value)
    callback()
  }

  const [service, setService] = useState("Tous")

  const handleClickService = (value, callback) => {
    setService(value)
    callback()
  }

  const [search, setSearch] = useState("")

  const handleSearch = event => {
    setSearch(event.target.value)
  }

  const [isInputShowed, setisInputShowed] = useState(false)

  const handleClickSearch = () => {
    if (isInputShowed) {
      document.getElementById("search").blur()
      setSearch("")
    } else {
      document.getElementById("search").focus()
    }
    setisInputShowed(!isInputShowed)
  }

  const getDataReferences = data => {
    const dataFiltered = data
      .filter(item =>
        sector !== "Tous"
          ? item.node.acf.secteur_banniere_etude_de_cas === sector
          : item
      )
      .filter(item =>
        service !== "Tous"
          ? item.node.acf.prestations.find(
              obj => obj.texte_prestation === service
            )
          : item
      )
      .filter(item =>
        search.length > 0
          ? item.node.acf.titre_banniere_etude_de_cas
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.node.acf.texte_banniere_etude_de_cas
              .toLowerCase()
              .includes(search.toLowerCase())
          : item
      )
    return dataFiltered
  }

  const getSectorList = data => {
    let sectorArray = []
    data.map(item =>
      !sectorArray.includes(
        item.node.acf.secteur_banniere_etude_de_cas.toLowerCase()
      )
        ? sectorArray.push(
            item.node.acf.secteur_banniere_etude_de_cas.toLowerCase()
          )
        : null
    )
    return sectorArray
  }

  const {
    articles,
    references,
    etudes_de_cas,
    bloc_temoignages,
    bloc_contact,
  } = data

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Nos Références"
      locationGTM={location.href}
    >
      <SEO
        title={references.acf.title}
        description={references.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        hasSlider
        location={location}
        bannerLeftCardContent={bannerLeftCardContent}
        hasBreadCrumb
        isReferencesPage
        articles={articles.edges.slice(Math.max(articles.edges.length - 3, 0))}
      />
      <Filter
        sector={sector}
        handleClickSector={handleClickSector}
        service={service}
        handleClickService={handleClickService}
        sectorList={getSectorList(etudes_de_cas.edges)}
        handleSearch={handleSearch}
        isInputShowed={isInputShowed}
        handleClickSearch={handleClickSearch}
      />
      {getDataReferences(etudes_de_cas.edges).length > 0 ? (
        <ReferencesList items={getDataReferences(etudes_de_cas.edges)} />
      ) : (
        <ResultText>
          Il n'y a pas d'étude de cas correspondant à votre recherche
        </ResultText>
      )}

      <Temoignage
        title={bloc_temoignages.acf.titre_temoignages}
        items={bloc_temoignages.acf.temoignages}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default References

export const query = graphql`
  query {
    references: wordpressPage(
      id: { eq: "cea78268-26c2-52cd-a207-43a6ed2bc28f" }
    ) {
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
        hashtag
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    etudes_de_cas: allWordpressWpEtudesDeCas(
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          path
          acf {
            creation_etude_de_cas
            titre_banniere_etude_de_cas
            texte_banniere_etude_de_cas
            secteur_banniere_etude_de_cas
            prestations {
              texte_prestation
            }
            image_vignette_etude_de_cas {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo_etude_de_cas {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            couleur_vignette_etude_de_cas
          }
        }
      }
    }
    bloc_temoignages: wordpressAcfBlocs(
      id: { eq: "bb45179a-ff3e-53bb-8add-80a2dec1388b" }
    ) {
      acf {
        titre_temoignages
        temoignages {
          nom_temoignage
          texte_temoignage
          fonction_temoignage
          logo_temoignage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
    articles: allWordpressPost(
      limit: 3
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            niveau_article
            titre_article
            intro_article
            date_de_publication
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            temps_de_lecture
          }
        }
      }
    }
  }
`
